import {
  Box,
  Typography,
  Checkbox,
  Tab,
  TextField as MUITextField,
  Autocomplete,
  Chip,
} from '@mui/material'
import { useEffect, useState, useMemo } from 'react'
import { Form, IDialogType, SelectField, TextField } from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { notificationApi } from 'resources/notification'
import { INotification } from 'models/Notification'
import {
  NOTIFICATION_EVENTS_BY_MODE,
  NOTIFICATION_TYPES_BY_MODE,
  TIME_UNITS,
} from '../constants'
import { TabContext, TabList } from '@mui/lab'
import { IMember } from 'models'
import { locationApi, memberApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import useLoadingState from 'hooks/useLoadingState'
import { DropdownOption } from 'types'
import useLocations from 'hooks/useLocations'

interface INotificationFormProps {
  notification: INotification | undefined
  success: () => void
  onClose: () => void
  modes: DropdownOption[]
  notificationTypes: DropdownOption[]
  displayMessage: (message: string, type?: IDialogType) => void
}

interface TeamMemberDropdownOption extends DropdownOption {
  email: string
}

const NotificationForm = ({
  notification,
  success,
  onClose,
  modes,
  notificationTypes,
  displayMessage,
}: INotificationFormProps): React.ReactElement => {
  const [filteredNotificationTypes, setFilteredNotificationTypes] = useState<
    DropdownOption[]
  >([])
  const [filteredEvents, setFilteredEvents] = useState<DropdownOption[]>([])
  const [locationsToAdd, setLocationsToAdd] = useState<string[]>([])
  const [locationsToRemove, setLocationsToRemove] = useState<string[]>([])
  const [teamMembers, setTeamMembers] = useState<IMember[]>([])
  const [teamMembersOptions, setTeamMembersOptions] = useState<
    TeamMemberDropdownOption[]
  >([])
  const [selectedTeamMember, setSelectedTeamMember] =
    useState<TeamMemberDropdownOption | null>(() => {
      if (notification && notification.id_member && notification.member) {
        return {
          label: notification.member.name,
          value: notification.member.user_id,
          email: notification.member.email,
        }
      }
      return null
    })
  const [before, setBefore] = useState<boolean>(
    notification ? notification?.before : false,
  )
  const [after, setAfter] = useState<boolean>(
    notification ? notification?.after : true,
  )

  const { create, update } = notificationApi()
  const { getMany: getTeamMembers } = memberApi()

  const { loading, setLoading } = useLoadingState()
  const { loading: loadingTeamMembers, setLoading: setLoadingTeamMembers } =
    useLoadingState()

  const { loadingLocations, locationsOptions, locations } = useLocations()

  const methods = useForm<INotification>({
    defaultValues: {
      ...notification,
    },
  })

  const mode = methods.watch('mode')

  const timeUnit = methods.watch('time_unit')

  const filteredLocations = useMemo(() => {
    if (notification) {
      return locationsOptions.filter(
        (option) => !notification.locations.some((l) => l.id == option.value),
      )
    }
    return locationsOptions
  }, [locationsOptions])

  const handleSubmit = async (data): Promise<void> => {
    const locationsArr = !notification
      ? [...locationsToAdd]
      : [
          ...locationsToAdd,
          ...notification?.locations
            .filter((location) => !locationsToRemove.includes(location.id))
            .map((location) => location.id),
        ]

    const newNotification = {
      ...data,
      locations: locationsArr,
    }

    try {
      setLoading(true)
      if (notification) {
        await update(notification.id, {
          ...newNotification,
          before,
          after,
          ...(selectedTeamMember && { id_member: selectedTeamMember.value }),
        })
        onClose()
        displayMessage('Notification updated successfully', 'success')
        success()
      } else {
        await create({
          ...newNotification,
          before,
          after,
          ...(selectedTeamMember && { id_member: selectedTeamMember.value }),
        })
        onClose()
        displayMessage('Notification created successfully', 'success')
        success()
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchTeamMembers = async (): Promise<void> => {
    try {
      setLoadingTeamMembers(true)
      const teamMembers = await getTeamMembers(1, 150, '')
      setTeamMembers(teamMembers.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleToggleWhen = (event) => {
    if (event.target.checked) {
      setBefore((previousValue) => !previousValue)
      setAfter((previousValue) => !previousValue)
    }
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const locationsSelected: string[] = value.map((option) => option.value)
    setLocationsToAdd(locationsSelected)
  }

  const handleMemberSelection = (
    event: React.SyntheticEvent,
    value: TeamMemberDropdownOption | null,
  ) => {
    setSelectedTeamMember(value)
  }

  const buildMembersOptions = () => {
    if (teamMembers.length > 0) {
      const autoCompleteOptions = teamMembers.map((member) => ({
        label: member.name,
        value: member.user_id,
        email: member.email,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setTeamMembersOptions(sortedOptions)
    }
  }

  const handleSelect = (locationId: string) => {
    if (locationsToRemove.includes(locationId)) {
      setLocationsToRemove(locationsToRemove.filter((id) => id !== locationId))
    } else {
      setLocationsToRemove((prevState) => [...prevState, locationId])
    }
  }

  useEffect(() => {
    if (mode) {
      const filterMode = mode === 'service' ? mode : 'all-modes'
      const filteredTypes = notificationTypes.filter((type) =>
        NOTIFICATION_TYPES_BY_MODE[filterMode].includes(type.value),
      )
      setFilteredNotificationTypes(filteredTypes)
      setFilteredEvents(NOTIFICATION_EVENTS_BY_MODE[mode])
    }
  }, [mode])

  useEffect(() => {
    if (timeUnit === 'immediately') {
      methods.setValue('time_amount', 0)
    }
  }, [timeUnit])

  useEffect(() => {
    setLoadingTeamMembers(false)
  }, [teamMembersOptions])

  useEffect(() => {
    buildMembersOptions()
  }, [locations, teamMembers])

  useEffect(() => {
    fetchTeamMembers()
  }, [])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  return (
    <>
      <TabContext value={'1'}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList centered>
            <Tab label="Notification" value="1" />
          </TabList>
        </Box>
      </TabContext>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit}>
          <FormWrapper
            title={notification ? 'Edit Notification' : 'Add Notification'}
          >
            <SelectField
              name="mode"
              label="Mode"
              items={modes}
              isReadOnly={notification && notification.is_template}
              rules={{ required: 'Mode is required' }}
            />

            {methods.watch('mode') && (
              <TextField
                name="name"
                label="Name"
                rules={{ required: 'Name is required' }}
              />
            )}

            {methods.watch('name') && (
              <>
                <Typography variant="h5">When?</Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      gap: '10px',
                    }}
                  >
                    {timeUnit !== 'immediately' && (
                      <TextField
                        name="time_amount"
                        label="Duration"
                        onlyInteger={true}
                        rules={{
                          required: 'Duration is required',
                          min: {
                            value: timeUnit === 'immediately' ? 0 : 1,
                            message: 'Duration has to be grater than 0',
                          },
                        }}
                      />
                    )}
                    <SelectField
                      name="time_unit"
                      label="Unit"
                      items={TIME_UNITS}
                      rules={{ required: 'Unit is required' }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Box>
                      <Checkbox
                        onChange={handleToggleWhen}
                        checked={before}
                        name="before"
                      />{' '}
                      Before
                    </Box>

                    <Box>
                      <Checkbox
                        onChange={handleToggleWhen}
                        checked={after}
                        name="after"
                      />{' '}
                      After
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {methods.watch('time_unit') &&
              (methods.watch('time_amount') ||
                methods.watch('time_amount') === 0) &&
              methods.watch('name') && (
                <SelectField
                  name="notification_type"
                  label="Event"
                  items={filteredEvents}
                  isReadOnly={notification && notification.is_template}
                />
              )}

            {methods.watch('time_unit') &&
              (methods.watch('time_amount') ||
                methods.watch('time_amount') === 0) &&
              methods.watch('name') &&
              methods.watch('notification_type') && (
                <TextField
                  name="message"
                  label="Message"
                  multiline={true}
                  rules={{ required: 'Message is required' }}
                />
              )}

            {/* {methods.watch('time_unit') &&
              (methods.watch('time_amount') ||
                methods.watch('time_amount') === 0) &&
              methods.watch('name') &&
              methods.watch('notification_type') && (
                <>
                  {methods.watch('notification_type') ===
                  'non_locker_delivery' ? (
                    <>
                      <TextField
                        name="message"
                        label="Message for driver"
                        multiline={true}
                        rules={{ required: 'Message for driver is required' }}
                      />
                      <TextField
                        name="message"
                        label="Message for user"
                        multiline={true}
                        rules={{ required: 'Message for user is required' }}
                      />
                    </>
                  ) : (
                    <TextField
                      name="message"
                      label="Message"
                      multiline={true}
                      rules={{ required: 'Message is required' }}
                    />
                  )}
                </>
              )} */}

            {methods.watch('time_unit') &&
              (methods.watch('time_amount') ||
                methods.watch('time_amount') === 0) &&
              methods.watch('name') &&
              methods.watch('notification_type') &&
              methods.watch('message') && (
                <>
                  <Typography variant="h5">Assign Locations</Typography>
                  <Autocomplete
                    multiple
                    options={filteredLocations}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <MUITextField {...params} label="Search locations..." />
                    )}
                    onChange={handleToggle}
                    loading={loadingLocations}
                    loadingText={'Loading locations...'}
                  />

                  {notification && (
                    <Typography variant="h5">Locations assigned</Typography>
                  )}
                  {notification && (
                    <Typography variant="caption" color="rgb(173, 176, 187)">
                      {notification.locations.length > 0
                        ? 'Select the locations that you want to remove and hit submit'
                        : 'No locations assigned'}
                    </Typography>
                  )}
                  {notification && notification?.locations.length > 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        gap: '0.75rem',
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      {notification.locations.map((location) => (
                        <Chip
                          key={location.id}
                          label={location.name}
                          onClick={() => handleSelect(location.id)}
                          onDelete={() => handleSelect(location.id)}
                          deleteIcon={
                            locationsToRemove.includes(location.id) ? (
                              <DoneIcon />
                            ) : (
                              <DeleteIcon />
                            )
                          }
                          variant={
                            locationsToRemove.includes(location.id)
                              ? 'filled'
                              : 'outlined'
                          }
                          color={
                            locationsToRemove.includes(location.id)
                              ? 'primary'
                              : 'default'
                          }
                        />
                      ))}
                    </Box>
                  )}
                </>
              )}

            {methods.watch('time_unit') &&
              (methods.watch('time_amount') ||
                methods.watch('time_amount') === 0) &&
              methods.watch('name') &&
              methods.watch('notification_type') &&
              methods.watch('message') &&
              methods.watch('notification_type') === 'on_expired' && (
                <>
                  <Typography variant="h5">Assign Team Member</Typography>
                  <Autocomplete
                    value={selectedTeamMember}
                    options={teamMembersOptions}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography>{option.label}</Typography>
                          <Typography color="text.secondary">
                            {option.email}
                          </Typography>
                        </Box>
                      </li>
                    )}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <MUITextField {...params} label="Team member..." />
                    )}
                    onChange={handleMemberSelection}
                    loading={loadingTeamMembers}
                    loadingText={'Loading team members...'}
                    disableCloseOnSelect={true}
                    getOptionLabel={(option) => option.label}
                  />
                </>
              )}

            {selectedTeamMember && (
              <TextField
                name="member_message"
                label="Mesage for team member"
                placeholder="Mesage for team member"
              />
            )}

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default NotificationForm
