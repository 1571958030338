import { Box, Button } from '@mui/material'
import {
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import ReservationForm from './ReservationForm'
import useDialog from 'hooks/useDialog'
import { IDevice, ILocation, ISize, IUser } from 'models'
import { deviceApi, locationApi, sizeApi, userApi } from 'resources'
import { IProduct } from 'models/Product'
import { productApi } from 'resources/product'
import SettingsForm from './SettingsForm'
import { IReservation } from 'models/Reservations'
import ReservationRow from './ReservationRow'
import { reservationApi } from 'resources/reservations'
import useSortableHeader from 'hooks/useSortableHeader'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'
import ReservationSettingsForm from './ReservationSettingsForm'
import useDeviceModes from 'hooks/useDeviceModes'
import useLocations from 'hooks/useLocations'

const Reservations = () => {
  const [reservations, setReservations] = useState<any>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [reservationsBackup, setReservationsBackup] = useState<any>([])
  const [currentReservation, setCurrentReservation] = useState<
    IReservation | undefined
  >()
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [openSidebar, setOpenSidebar] = useState(false)

  const [openSettings, setOpenSettings] = useState<boolean>(false)
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [users, setUsers] = useState<IUser[]>([])
  const [sizes, setSizes] = useState<ISize[]>([])
  const [devices, setDevices] = useState<IDevice[]>([])
  const [products, setProducts] = useState<IProduct[]>([])

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })
  const { loading, setLoading } = useLoadingState(true)
  const { loading: loadingDevices, setLoading: setLoadingDevices } =
    useLoadingState(true)

  const { getMany: getLocations } = locationApi()
  const { getMany: getDevices } = deviceApi()
  const { getMany: getProducts } = productApi()
  const { getMany: getSizes } = sizeApi()
  const { getMany, remove } = reservationApi()
  const { getMany: getUsers } = userApi()
  const { modes, isLoadingModes } = useDeviceModes()

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrder: 'desc',
      defaultOrderBy: 'created_at',
      entity: 'reservations',
      nestedProps: [
        {
          columnValue: 'user',
          path: 'user.name',
          defaultValue: '',
        },
        {
          columnValue: 'phone',
          path: 'user.phone_number',
          defaultValue: 0,
        },
        {
          columnValue: 'email',
          path: 'user.email',
          defaultValue: '',
        },
        {
          columnValue: 'assigned_locker',
          path: 'device.locker_number',
          defaultValue: 0,
        },
        {
          columnValue: 'location',
          path: 'location.name',
          defaultValue: '',
        },
        {
          columnValue: 'size',
          path: 'size.name',
          defaultValue: '',
        },
      ],
    })

  const sizeOptions = useMemo(
    () =>
      sizes.map((size) => ({
        value: size.id,
        label: `${size.name} (${size.width}" x ${size.depth}" x ${size.height}")`,
      })),
    [sizes],
  )

  const productsOptions = useMemo(
    () =>
      products.map((product) => ({
        value: product.id,
        label: `${product.name} (${product.sku})`,
      })),
    [products],
  )

  const fetchReservations = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    try {
      setLoading(true)
      const reservations = await getMany(currentPage + 1, rowsPerPage, search)
      setReservationsBackup([...reservations.items])
      setReservations(reservations)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchUsers = async (): Promise<void> => {
    try {
      const users = await getUsers(1, 10000, '')
      setUsers(users.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchSizes = async (): Promise<void> => {
    try {
      const sizes = await getSizes(1, 10000)
      setSizes(sizes.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchDevices = async (): Promise<void> => {
    try {
      setLoadingDevices(true)
      const devices = await getDevices(1, 10000)
      setDevices(devices.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchProducts = async (): Promise<void> => {
    try {
      const products = await getProducts(1, 10000)
      setProducts(products.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  useEffect(() => {
    if (loadingDevices) setLoadingDevices(false)
  }, [devices])

  const handleEdit = (reservation: IReservation) => {
    setOpenForm(true)
    setOpenSidebar(true)
    setCurrentReservation(reservation)
  }

  const handleCloseSidebar = () => {
    if (openForm) {
      setOpenForm(false)
    }
    if (openSettings) {
      setOpenSettings(false)
    }
    setOpenSidebar(false)
    setCurrentReservation(undefined)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? reservations.items.map((reservation) => reservation.id) : [],
    )
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchReservations()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  useEffect(() => {
    getDefaultFilters()
    fetchReservations()
    fetchUsers()
    fetchDevices()
    fetchProducts()
    fetchSizes()
  }, [])

  useEffect(() => {
    fetchReservations()
  }, [search, currentPage, rowsPerPage])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(reservations.items, reservationsBackup),
    [reservations, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => {
            setOpenForm(true)
            setOpenSidebar(true)
          }}
        >
          Add Reservation
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => {
            setOpenSettings(true)
            setOpenSidebar(true)
          }}
        >
          Reservation Settings
        </Button>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={
            modes.some((m) => m.value === 'delivery' && modes.length === 1)
              ? filteredColumns.filter((c) => c.value !== 'duration')
              : filteredColumns
          }
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          handleAction={handleDeleteMany}
        />
      )}
      {!loading && reservations.items.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={
                modes.some((m) => m.value === 'delivery' && modes.length === 1)
                  ? filteredColumns.filter(
                      (c) => c.active && c.value !== 'duration',
                    )
                  : filteredColumns.filter((c) => c.active)
              }
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.length > 0 &&
                visibleRows.map((reservation: IReservation) => (
                  <ReservationRow
                    key={reservation.id}
                    reservation={reservation}
                    success={fetchReservations}
                    handleEdit={handleEdit}
                    filteredColumns={
                      modes.some(
                        (m) => m.value === 'delivery' && modes.length === 1,
                      )
                        ? filteredColumns.filter((c) => c.value !== 'duration')
                        : filteredColumns
                    }
                    displayMessage={displayMessage}
                    handleSelectRow={handleSelectRow}
                    selected={selectedRows.includes(reservation.id)}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={reservations.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingTableData isLoading={loading || isLoadingModes} />

      <NoData condition={!loading && reservations.items.length === 0} />

      <SideBar open={openSidebar} onClose={handleCloseSidebar}>
        {openForm && (
          <ReservationForm
            reservation={currentReservation}
            success={fetchReservations}
            onClose={handleCloseSidebar}
            displayMessage={displayMessage}
            users={users}
            sizes={sizes}
            productsOptions={productsOptions}
            devices={devices}
            isLoadingDevices={loadingDevices}
          />
        )}
        {openSettings && (
          <ReservationSettingsForm
            onClose={handleCloseSidebar}
            displayMessage={displayMessage}
          />
        )}
      </SideBar>

      <NotificationDialog
        message={dialog.message}
        open={dialog.isOpen}
        onClose={closeDialog}
        type={dialog.type}
      />
    </>
  )
}

export default Reservations
