import {
  Autocomplete,
  Box,
  Checkbox,
  Typography,
  TextField as MUITextField,
  Chip,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material'
import { Form, IDialogType, Image, MapField, TextField } from 'components'
import { IGroup, ILocation, IUser } from 'models'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { groupApi, locationApi, userApi } from 'resources'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { formatLocationFormData } from '../helper'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { Help } from 'components/Help'
import useIsSuperTenantOrg from 'hooks/useIsSuperTenantOrg'
import ImageUploader from 'components/ImageUploader/ImageUploader'
import { ToggleSwitch } from 'components/ToggleSwitch'
import { DropdownOption } from 'types'
import { capitalizeFirstLetter } from 'utils/helpers'
import { useLocationsContext } from '../context'

interface IGeneralProps {
  location: ILocation | undefined
  onClose: () => void
  allowEdit: boolean
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
  setCurrentLocation: (location: ILocation) => void
  goToDevicesTab: () => void
}

const General = ({
  location,
  onClose,
  // success,
  allowEdit = true,
  displayMessage,
  setCurrentLocation,
  goToDevicesTab,
}: IGeneralProps) => {
  const [currentPosition, setCurrentPosition] = useState({
    lat: 0,
    lng: 0,
  })
  const [address, setAddress] = useState<string>('')
  const [image, setImage] = useState<File | null>(null)
  const [previewImage, setImagePreview] = useState('')
  const [isRestricted, setIsRestricted] = useState<boolean>(
    Boolean(location?.restriction),
  )
  const [restrictedTo, setRestrictedTo] = useState<string>(
    location?.restriction
      ? capitalizeFirstLetter(location.restriction.restriction_type)
      : 'Users',
  )
  const [users, setUsers] = useState<IUser[]>([])
  const [groups, setGroups] = useState<IGroup[]>([])
  const [usersToAdd, setUsersToAdd] = useState<string[]>([])
  const [groupsToAdd, setGroupsToAdd] = useState<string[]>([])
  const [resourcesToDelete, setResourcesToDelete] = useState<string[]>([])
  const [options, setOptions] = useState<DropdownOption[]>([])
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [loadingResources, setLoadingResources] = useState(false)
  const [isShared, setIsShared] = useState<boolean>(
    // location ? location.shared : false,
    false,
  )
  const [isHidden, setIsHidden] = useState<boolean>(
    location ? location.hidden : false,
  )

  const ref = useRef(true)

  const [customerSupportSelection, setCustomerSupportSelection] = useState(
    () => {
      if (!location) {
        return 'use_default_cs'
      } else {
        const settings = JSON.parse(localStorage.getItem('settings') || '{}')

        if (
          location?.contact_email === settings.default_support_email &&
          location?.contact_phone === settings.default_support_phone
        ) {
          return 'use_default_cs'
        }

        return 'add_new'
      }
    },
  )

  const { loading, setLoading } = useLoadingState()
  const { fetchLocations } = useLocationsContext()

  const {
    assign: assignUserToLocation,
    getMany: getUsers,
    assigned: getUsersFromLocation,
    unassign: removeUserFromLocation,
  } = userApi()
  const {
    assign: assignGroupToLocation,
    getMany: getGroups,
    assigned: getGroupsFromLocation,
    unassign: removeGroupFromLocation,
  } = groupApi()
  const { create, update } = locationApi()

  const methods = useForm<ILocation>({
    defaultValues: {
      ...location,
    },
  })

  const isSuperTenantOrg = useIsSuperTenantOrg()

  const getFormTitle = useCallback(() => {
    if (location && allowEdit && isSuperTenantOrg) {
      return 'Edit Location'
    } else if (!location && allowEdit) {
      return 'Add Location'
    } else if (location && location.shared && !isSuperTenantOrg)
      return 'Location Details'
    else return 'Location Details'
  }, [isSuperTenantOrg])

  const fetchUsers = async (): Promise<void> => {
    try {
      setLoadingResources(true)
      const users = await getUsers(1, 10000, '')
      setUsers(users.items)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    }
  }

  const fetchGroups = async (): Promise<void> => {
    try {
      const groups = await getGroups(1, 10000, '')
      setGroups(groups.items)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    }
  }

  const handleImageClick = (): void => {
    document.getElementById('image')?.click()
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file: File | undefined = event?.target?.files?.[0]
    const isImage = file && /^image\//.test(file.type)

    if (!isImage) {
      displayMessage?.('Only images are allowed!', 'info')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (e) =>
      e.target && e.target.result && setImagePreview(e.target.result as string)

    setImage(file ?? null)
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const selectedResources: string[] = value.map((option) => option.value)
    if (restrictedTo === 'Users') {
      setUsersToAdd(selectedResources)
    } else {
      setGroupsToAdd(selectedResources)
    }
  }

  const buildUserOptions = () => {
    if (users.length > 0) {
      const notAssignedUsers = users.filter(
        (user) => !location?.restriction?.items.some((u) => u.id === user.id),
      )
      const autoCompleteOptions = notAssignedUsers.map((user) => ({
        label: `${user.name || ''} (${user.email || user.phone_number})`,
        value: user.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(sortedOptions)
    }
    setLoadingResources(false)
  }

  const buildGroupOptions = () => {
    if (groups.length > 0) {
      const notAssignedGroups = groups.filter(
        (group) => !location?.restriction?.items.some((g) => g.id === group.id),
      )
      const autoCompleteOptions = notAssignedGroups.map((group) => ({
        label: group.name,
        value: group.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(sortedOptions)
    }
    setLoadingResources(false)
  }

  const handleRadioCustomerSupport = (event) => {
    const settings = JSON.parse(localStorage.getItem('settings') || '{}')
    const { value } = event.target
    setCustomerSupportSelection(value)
    if (value === 'use_default_cs') {
      methods.setValue('contact_phone', settings.default_support_phone)
      methods.setValue('contact_email', settings.default_support_email)
    } else {
      methods.setValue(
        'contact_phone',
        customerSupportSelection === 'add_new' &&
          location &&
          location.contact_phone !== settings.default_support_phone
          ? location.contact_phone
          : ' ',
      )
      methods.setValue(
        'contact_email',
        customerSupportSelection === 'add_new' &&
          location &&
          location.contact_email !== settings.default_support_email
          ? location.contact_email
          : ' ',
      )
    }
    methods.clearErrors()
  }

  const handleRestrictionChange = (newRestriction: string) => {
    if (
      restrictedTo === 'Users' &&
      (usersToAdd.length > 0 ||
        (location?.restriction && location?.restriction?.items.length > 0)) &&
      newRestriction === 'Groups'
    ) {
      displayMessage?.(
        'You have selected/assigned users to this location, please unselect/remove them if you want to change the restriction type.',
        'warning',
      )
    } else if (
      restrictedTo === 'Groups' &&
      (groupsToAdd.length > 0 ||
        (location?.restriction && location?.restriction?.items.length > 0)) &&
      newRestriction === 'Users'
    ) {
      displayMessage?.(
        'You have selected/assigned groups to this location, please unselect/remove them if you want to change the restriction type.',
        'warning',
      )
    } else {
      setRestrictedTo(newRestriction)
    }
  }

  const handleSelect = (resourceId: string) => {
    if (resourcesToDelete.includes(resourceId)) {
      setResourcesToDelete(resourcesToDelete.filter((id) => id !== resourceId))
    } else {
      setResourcesToDelete((prevState) => [...prevState, resourceId])
    }
  }

  const assignUsers = async (locationId) => {
    const promises = usersToAdd.map(
      async (userId) =>
        await assignUserToLocation(userId, locationId, 'location'),
    )
    await Promise.all(promises)
  }

  const assignGroups = async (locationId) => {
    const promises = groupsToAdd.map(
      async (groupId) =>
        await assignGroupToLocation(locationId, groupId, 'location'),
    )
    await Promise.all(promises)
  }

  const unassignUsers = async (ids: string[] | null = null) => {
    if (location) {
      const resourcesToUnassign = ids || resourcesToDelete
      const promises = resourcesToUnassign.map(
        async (userId) =>
          await removeUserFromLocation(userId, location?.id, 'location'),
      )
      await Promise.all(promises)
    }
  }

  const unassignGroups = async (ids: string[] | null = null) => {
    const resourcesToUnassign = ids || resourcesToDelete
    const promises = resourcesToUnassign.map(
      async (groupId) =>
        await removeGroupFromLocation(location?.id, groupId, 'location'),
    )
    await Promise.all(promises)
  }

  const updateOrCreateLocation = async (newLocation: ILocation) => {
    // format payload
    const formattedLocation = formatLocationFormData({
      ...newLocation,
      address,
      latitude: currentPosition.lat,
      longitude: currentPosition.lng,
      assignment_type:
        restrictedTo === 'Users'
          ? 'user'
          : restrictedTo === 'Groups'
          ? 'group'
          : null,
      image: image,
      // shared: isShared,
      shared: false,
      hidden: isHidden,
    })
    // create or update location
    if (location) {
      if (
        !isRestricted &&
        location.restriction &&
        location.restriction.items.length > 0
      ) {
        if (location.restriction.restriction_type === 'users') {
          await unassignUsers(location.restriction.items.map((user) => user.id))
        } else {
          await unassignGroups(
            location.restriction.items.map((group) => group.id),
          )
        }
      }
      const result = await update(location.id, formattedLocation)

      // assign users or groups to location
      if (restrictedTo === 'Users' && usersToAdd.length > 0) {
        await assignUsers(result.id)
      } else if (restrictedTo === 'Groups' && groupsToAdd.length > 0) {
        await assignGroups(result.id)
      }

      // unassign users or groups from location
      if (resourcesToDelete.length > 0 && location) {
        if (restrictedTo === 'Users') {
          await unassignUsers()
        } else if (restrictedTo === 'Groups') {
          await unassignGroups()
        }
      }
      onClose()
      fetchLocations()
      displayMessage?.('Location updated successfully!', 'success')
    } else {
      const result = await create(formattedLocation)

      // assign users or groups to location
      if (restrictedTo === 'Users' && usersToAdd.length > 0) {
        await assignUsers(result.id)
      } else if (restrictedTo === 'Groups' && groupsToAdd.length > 0) {
        await assignGroups(result.id)
      }
      setCurrentLocation({ ...result })
      onClose()
      fetchLocations()
      displayMessage?.(
        <p>
          Location created successfully. You can assign devices to this location
          on Devices tab.
        </p>,
        'success',
      )
    }
  }

  const handleSubmit = async (data: ILocation) => {
    try {
      setLoading(true)
      await updateOrCreateLocation(data)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (restrictedTo === 'Users') {
      buildUserOptions()
    } else {
      buildGroupOptions()
    }
  }, [restrictedTo, users, groups])

  useEffect(() => {
    if (!isRestricted) {
      setUsersToAdd([])
      setGroupsToAdd([])
    }
  }, [isRestricted])

  useEffect(() => {
    fetchUsers()
    fetchGroups()
  }, [])

  useEffect(() => {
    if (location) {
      setCurrentPosition({
        lat: location?.latitude ?? 41.87,
        lng: location?.longitude ?? -87.62,
      })
      setAddress(location?.address)
    } else {
      setCurrentPosition({
        lat: 41.87,
        lng: -87.62,
      })
    }
  }, [location])

  useEffect(() => {
    if (!isFirstRender && customerSupportSelection === 'add_new') {
      const phone = methods.watch('contact_phone')
      const email = methods.watch('contact_email')
      const settings = JSON.parse(localStorage.getItem('settings') || '{}')
      if (
        phone &&
        email &&
        phone !== settings.default_support_phone &&
        email !== settings.default_support_email
      ) {
        methods.clearErrors('contact_phone')
        methods.clearErrors('contact_email')
        methods.setValue(
          'contact_phone',
          location && location.contact_phone !== settings.default_support_phone
            ? location.contact_phone
            : ' ',
        )
        methods.setValue(
          'contact_email',
          location && location.contact_email !== settings.default_support_email
            ? location.contact_email
            : ' ',
        )
      } else {
        methods.setValue('contact_phone', ' ')
        methods.setValue('contact_email', ' ')
      }
    }
  }, [customerSupportSelection])

  useEffect(() => {
    if (customerSupportSelection === 'use_default_cs') {
      const settings = JSON.parse(localStorage.getItem('settings') || '{}')
      methods.setValue('contact_phone', settings.default_support_phone)
      methods.setValue('contact_email', settings.default_support_email)
    }
  }, [])

  useEffect(() => {
    methods.setValue(
      'contact_phone',
      methods.watch('contact_phone')?.replace(' ', ''),
    )
    methods.setValue(
      'contact_email',
      methods.watch('contact_email')?.replace(' ', ''),
    )
  }, [methods.watch('contact_email'), methods.watch('contact_phone')])

  useEffect(() => {
    const firstRender = ref.current

    if (firstRender) {
      setIsFirstRender(false)
    }
  })

  const disableSubmit = Object.keys(methods.formState.errors).length > 0
  const isReadOnly =
    Boolean(!allowEdit) ||
    (location?.shared === true &&
      !isSuperTenantOrg &&
      process.env.REACT_APP_CURRENT_ENV !== 'production')

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit}>
        <FormWrapper title={getFormTitle()}>
          {/* {isSuperTenantOrg &&
            process.env.REACT_APP_CURRENT_ENV !== 'production' && (
              <>
                <Help helpText="Share this resource with Sub Organizations">
                  <Typography variant="h5">Share Location</Typography>
                </Help>
                <ToggleSwitch
                  leftItem={{
                    label: 'Standard',
                    onClick: () => setIsShared(false),
                    selected: !isShared,
                  }}
                  rightItem={{
                    label: 'Shared',
                    onClick: () => setIsShared(true),
                    selected: isShared,
                  }}
                />
              </>
            )} */}

          <TextField
            name="name"
            label="Name"
            placeholder="Name"
            rules={{
              required: 'Name required',
            }}
            disabled={isReadOnly}
          />

          <MapField
            currentPosition={currentPosition}
            setCurrentPosition={setCurrentPosition}
            address={address}
            setAddress={setAddress}
            isReadOnly={isReadOnly}
          />
          {previewImage && (
            <>
              <Typography>
                Preview <b>{image ? image.name : ''}</b>
              </Typography>
              <Image
                src={previewImage}
                alt="Location Image"
                style={{ outline: 'none' }}
              />
            </>
          )}

          {location?.image && previewImage === '' && (
            <>
              <Typography variant="h5">Location Image:</Typography>
              <Image
                src={location.image}
                alt="Location Image"
                style={{ outline: 'none' }}
              />
            </>
          )}

          {!isReadOnly && (
            <ImageUploader
              buttonText={location?.image ? 'Update Image' : 'Upload Image *'}
              helpText="Upload a rectangular image preferably of size 400x250 in high resolution for better display results."
              onChange={handleImageUpload}
            />
          )}

          <Help helpText="This allows the type of access for the location. Restricted will have a limit of access for the location whereas Unrestricted will have unlimited access for the location">
            <Typography variant="h5">Assign access</Typography>
          </Help>
          <ToggleSwitch
            leftItem={{
              label: 'Unrestricted',
              onClick: () => setIsRestricted(false),
              selected: !isRestricted,
            }}
            rightItem={{
              label: 'Restricted',
              onClick: () => setIsRestricted(true),
              selected: isRestricted,
            }}
            isReadOnly={isReadOnly}
          />

          {isRestricted && (
            <>
              <Typography variant="h5">
                How would you like to assign access?
              </Typography>
              <ToggleSwitch
                leftItem={{
                  label: 'Users',
                  onClick: () => handleRestrictionChange('Users'),
                  selected: restrictedTo === 'Users',
                }}
                rightItem={{
                  label: 'Groups',
                  onClick: () => handleRestrictionChange('Groups'),
                  selected: restrictedTo === 'Groups',
                }}
                isReadOnly={isReadOnly}
              />
            </>
          )}

          {isRestricted && restrictedTo && !isReadOnly && (
            <Autocomplete
              disabled={isReadOnly}
              multiple
              options={options}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField
                  {...params}
                  label={`Search ${
                    restrictedTo === 'Users' ? 'users' : 'groups'
                  }...`}
                />
              )}
              onChange={handleToggle}
              loading={loadingResources}
              loadingText={`Loading ${
                restrictedTo === 'Users' ? 'users' : 'groups'
              }...`}
              noOptionsText={`No ${
                restrictedTo === 'Users' ? 'users' : 'groups'
              } available`}
            />
          )}
          {isRestricted && restrictedTo && (
            <>
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'left',
                }}
              >
                {restrictedTo} assigned to this location
              </Typography>

              {!isReadOnly && (
                <>
                  {restrictedTo === 'Users' && (
                    <Typography variant="caption" color="rgb(173, 176, 187)">
                      {location?.restriction &&
                      location.restriction.items.length > 0
                        ? `Select the users that you want to remove from this location and hit submit`
                        : `No users assigned to this location.`}
                    </Typography>
                  )}
                  {restrictedTo === 'Groups' && (
                    <Typography variant="caption" color="rgb(173, 176, 187)">
                      {location?.restriction &&
                      location.restriction.items.length > 0
                        ? `Select the groups that you want to remove from this location and hit submit`
                        : `No groups assigned to this location.`}
                    </Typography>
                  )}
                </>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  gap: '0.75rem',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {location?.restriction &&
                  location.restriction.items.length > 0 &&
                  restrictedTo === 'Groups' &&
                  location.restriction.items.map((group) => (
                    <Chip
                      key={group.id}
                      label={group.name}
                      onClick={() => handleSelect(group.id)}
                      onDelete={() => handleSelect(group.id)}
                      deleteIcon={
                        resourcesToDelete.includes(group.id) ? (
                          <DoneIcon />
                        ) : (
                          <DeleteIcon />
                        )
                      }
                      variant={
                        resourcesToDelete.includes(group.id)
                          ? 'filled'
                          : 'outlined'
                      }
                      color={
                        resourcesToDelete.includes(group.id)
                          ? 'primary'
                          : 'default'
                      }
                    />
                  ))}
                {location?.restriction &&
                  location.restriction.items.length > 0 &&
                  restrictedTo === 'Users' &&
                  location.restriction.items.map((user) => (
                    <Chip
                      key={user.id}
                      label={`${user.name || ''} (${
                        user.email || user.phone_number
                      })`}
                      onClick={() => handleSelect(user.id)}
                      onDelete={() => handleSelect(user.id)}
                      deleteIcon={
                        resourcesToDelete.includes(user.id) ? (
                          <DoneIcon />
                        ) : (
                          <DeleteIcon />
                        )
                      }
                      variant={
                        resourcesToDelete.includes(user.id)
                          ? 'filled'
                          : 'outlined'
                      }
                      color={
                        resourcesToDelete.includes(user.id)
                          ? 'primary'
                          : 'default'
                      }
                    />
                  ))}
              </Box>
            </>
          )}

          <Typography variant="h5">Status</Typography>
          <ToggleSwitch
            leftItem={{
              label: 'Hidden',
              onClick: () => setIsHidden(true),
              selected: isHidden,
            }}
            rightItem={{
              label: 'Live',
              onClick: () => setIsHidden(false),
              selected: !isHidden,
            }}
            isReadOnly={isReadOnly}
          />

          <Typography variant="h5">Customer Support</Typography>

          {!isReadOnly && (
            <RadioGroup row value="use_default_cs" name="radio-buttons">
              <FormControlLabel
                value="use_default_cs"
                control={<Radio />}
                label="Use Default"
                onChange={handleRadioCustomerSupport}
                checked={customerSupportSelection === 'use_default_cs'}
                disabled={isReadOnly}
              />

              <FormControlLabel
                value="add_new"
                control={<Radio />}
                label="Add New"
                onChange={handleRadioCustomerSupport}
                checked={customerSupportSelection === 'add_new'}
                disabled={isReadOnly}
              />
            </RadioGroup>
          )}

          <TextField
            name="contact_email"
            label="Email"
            // onChange={() => {
            //   methods.clearErrors('contact_email')
            // }}

            disabled={
              customerSupportSelection === 'use_default_cs' || isReadOnly
            }
            placeholder={
              customerSupportSelection === 'add_new' ? 'user@domain.com' : ''
            }
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
          />
          <TextField
            name="contact_phone"
            label="Phone Number"
            // onChange={() => {
            //   methods.clearErrors('contact_phone')
            // }}
            disabled={
              customerSupportSelection === 'use_default_cs' || isReadOnly
            }
            placeholder={
              customerSupportSelection === 'add_new' ? '+12345678900' : ''
            }
            rules={{
              required: 'Phone number is required',
              pattern: {
                value: /^[\+]?[(]?[0-9]*[)]?[-\s\.]?[0-9]*[-\s\.]?[0-9]{4,6}$/i,
                message: 'Invalid phone number',
              },
            }}
          />

          {!isReadOnly && (
            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          )}
        </FormWrapper>
      </Form>
    </FormProvider>
  )
}

export default General
